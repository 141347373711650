import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { saveSessionData, getSessionData } from '../components/utils';
import { useLocation } from 'react-router-dom';

const homeUrl = process.env.REACT_APP_HOME_URL;
const user = getSessionData('user');
const logout = () => {
  saveSessionData('user', null);
  window.location.href = homeUrl+'/login';
};

const TopHead = () => {
  const { campaignId } = useParams();
  const location = useLocation(); // Get the current location

  // Function to derive the title from the pathname
  const getTitle = () => {
    const path = location.pathname;

    // Create a title based on the URL name
    switch (path) {
      case '/':
        return 'Dashboard';
      case '/dashboard':
        return 'Dashboard';
      case '/campaigns':
        return 'Campaign';
      case '/analytics':
        return 'Analytics';
      case '/add-email':
        return 'Add Email';
      case '/reply-messages':
        return 'Reply Messages';
      case '/emailchecker':
        return 'Email Checker';
      case '/search-domain':
        return 'Search Domain';
      case '/domain-list':
        return 'Domain List';
      case `/campaign-emails/${campaignId}`:
        return 'Campaign';
      default:
        return ''; // Default title for unmatched routes
    }
  };

  return (

    <div className="tophead_mainsec">
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div className="title_mainsec">
            <h2>{getTitle()}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHead;
