import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getSessionData } from './utils';
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const apiUrl = process.env.REACT_APP_API_URL; 
const user = getSessionData('user'); 

const CampaignEmails = () => {
  const { campaignId } = useParams();
  const [campaigns, setCampaigns] = useState([]);
  const [dates, setDates] = useState([]);
  const [values, setValues] = useState([]);
  const [spamvalues, setSpamvalues] = useState([]);
  const [inboxvalues, setInboxvalues] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [total_email, setTotalemail] = useState(0); 
  const [spam_email, setSpamemail] = useState(0); 
  const [inbox_email, setInboxemail] = useState(0); 
  const [send_email, setSendemail] = useState(0); 
  const [received_email, setReceivedmail] = useState(0);
  const [warmup_emails, setWarmupmail] = useState(0);
  const [outbound, setOutbound] = useState(0);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [cName, setCName] = useState('Untitled Campaign');
  const [subject, setSubject] = useState('');
  const [cMsg, setCMsg] = useState('');
  const [first_name, setFirstname] = useState(''); 
  const [last_name, setLastname] = useState(''); 
  const [smtp_username, setSMTPusername] = useState(''); 
  const [smtp_password, setSMTPpassword] = useState(''); 
  const [smtp_host, setSMTPhost] = useState(''); 
  const [smtp_port, setSMTPport] = useState(''); 
  const [daily_limit, setDailylimit] = useState('');
  const [warmup_limit, setWarmuplimit] = useState('');
  const [warmup_enabled, setWarmupenabled] = useState('FALSE');
  const data = {
    labels: dates,
    datasets: [
      {
        label: "Send",
        data: values,
        backgroundColor: "#d477c9", // Bar color
        borderColor: "#d477c9", // Border color
        borderWidth: 1,
        barThickness: 40
      },
      {
        label: "Spam",
        data: spamvalues,
        backgroundColor: "red", // Bar color
        borderColor: "red", // Border color
        borderWidth: 1,
        barThickness: 40
      },
      {
        label: "Inbox",
        data: inboxvalues,
        backgroundColor: "blue", // Bar color
        borderColor: "blue", // Border color
        borderWidth: 1,
        barThickness: 40
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top", // Position of the legend
      },
      title: {
        display: true,
        text: "Email Send",
      },
    },
    scales: {
      x: {
        
          ticks: {
            color: "#d477c9",
          },
        stacked: true, // Enable stacking for the X-axis
      },
      y: {
        stacked: true, // Enable stacking for the Y-axis
        beginAtZero: true,
        ticks: {
          color: "#d477c9",
          callback: function (value) {
            return Number.isInteger(value) ? value : null;
          },
          stepSize: 1,
        },
      },
    },
  };

  const formatDate = (dateString) => {
     if (!dateString) return '';
    const date = new Date();
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'long', // Full weekday name
      month: 'short',  // Short month name
      day: 'numeric',  // Numeric day
      year: 'numeric', // Full year
    }).format(date);
  };

  const data1 = {
    labels: ["Landed in Inbox", "Saved from Spam"],
    datasets: [
      {
        label: "Email Distribution",
        data: [warmup_emails, outbound], // Example data
        backgroundColor: [
          "rgba(54, 162, 235, 0.8)", // Blue
          "rgba(255, 99, 132, 0.8)", // Red
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options1 = {
    responsive: true,
    cutout: "70%",
    plugins: {
      legend: {
        position: "top", // Legend position: top, bottom, left, or right
      },
      title: {
        display: true,
        text: "Email Performance Distribution",
      },
    },
  };

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/get_campaign_emails`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({ id: campaignId }),
        });

        const result = await response.json();
        if (response.ok) {
        	setDates(result.dates);
        	setValues(result.values);
        	setSpamvalues(result.spamvalues);
        	setInboxvalues(result.inboxvalues);
          setCampaigns(result.campaigns);
          setTotalemail(result.total_emails);
          setSpamemail(result.is_spam);
          setInboxemail(result.in_inbox);
          setSendemail(result.send_emails);
          setReceivedmail(result.is_send);
          setWarmupmail(result.warmup_emails);
          setOutbound(result.outbound);
        }
      } catch (error) {
        console.error("Error checking campaign name:", error);
      } finally {
        setLoading(false); 
      }
    };

    if (user && user.id) { 
      fetchCampaigns();
    } else {
      setLoading(false); // Turn off loading indicator
    }
  }, [campaignId, user]); 

const handleSave = async () => {
  try {
    // Construct the payload with fallback values
    const updatedCampaign = {
      id: campaigns?.id || campaignId,                
      campaign_name: campaigns?.campaign_name || cName, 
      subject: campaigns?.subject || subject,        
      campaign_message: campaigns?.campaign_message || cMsg,
      first_name: campaigns?.first_name || first_name,
      last_name: campaigns?.last_name || last_name,
      smtp_username: campaigns?.smtp_username || smtp_username,
      smtp_password: campaigns?.smtp_password || smtp_password,
      smtp_host: campaigns?.smtp_host || smtp_host,
      smtp_port: campaigns?.smtp_port || smtp_port,
      daily_limit: campaigns?.daily_limit || daily_limit,
      warmup_enabled: campaigns?.warmup_enabled || warmup_enabled,
      warmup_limit: campaigns?.warmup_limit || warmup_limit,
    };

    // API call
    const response = await fetch(`${apiUrl}/api/update_campaign`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedCampaign), // Send updated campaign state
    });

    const result = await response.json();
    if (response.ok) {
      alert('Campaign updated successfully');
    } else {
      console.error(result); // Log server errors for debugging
      alert('Error updating campaign');
    }
  } catch (error) {
    console.error('Error saving campaign:', error);
    alert('An error occurred while saving the campaign');
  }
};


  return (
    <div className="campaign_details">
	    <div className="tophead_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="title_mainsec">
              <h2>{campaigns ? `${campaigns.first_name} ${campaigns.last_name}` : ''} <span>({campaigns ? campaigns.smtp_username : ''})</span></h2>
            </div>
          </div>
        </div>
      </div>
      
      <div className="emaildetails_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="tabs_mainsec">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                </li>
                
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="general-tab" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="general" aria-selected="false">General</button>
                </li>
                
                {/*<li className="nav-item" role="presentation">
                  <button className="nav-link" id="warmup-tab" data-bs-toggle="tab" data-bs-target="#warmup" type="button" role="tab" aria-controls="warmup" aria-selected="false">Warm Up</button>
                </li>*/}
                {/*
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="management-tab" data-bs-toggle="tab" data-bs-target="#management" type="button" role="tab" aria-controls="management" aria-selected="false">Management</button>
                </li> */}
              </ul>
              
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                  <div className="overview_contntbox">
                    <div className="title_mainsec">
                      <h4>Summary (last 7 days)</h4>
                    </div>
                    
                    <div className="summary_mainbox">
                      <div className="innerbox">
                        <h3>{total_email}</h3>
                        <p>Warmup emails sent</p>
                      </div>
                      
                      <div className="innerbox">
                        <h3>{inbox_email}</h3>
                        <p>Landed in inbox</p>
                      </div>
                      
                      <div className="innerbox">
                        <h3>{spam_email}</h3>
                        <p>Saved from spam</p>
                      </div>
                      
                      <div className="innerbox">
                        <h3>{received_email}</h3>
                        <p>Emails received</p>
                      </div>
                    </div>
                    
                    <div className="emailoutbound _mainbox">
                      <div className="innerbox">
                        <div className="emailperformance_box">
                          <div className="title_sec">
                            <h4>Email Performance</h4>
                          </div>
                          
                          <div className="contnt_sec">
                            <h3>
                                {
                                  warmup_emails < 80 ? 'Review your accounts' : warmup_emails < 90 ? 'Needs improving' : 'Very good'
                                }
                              </h3>
                            <p>{warmup_emails}% of your warmup emails landed in inbox</p>
                          </div>
                        </div>
                      </div>
                      
                      <div className="innerbox">
                        <div className="outboundstatus_box">
                          <div className="title_sec">
                            <h4>Outbound Status</h4>
                          </div>
                          
                          <div className="progress_mainsec">
                            <div className="label_sec">
                              <span>Warmup</span>
                              <span>Outbound</span>
                            </div>
                            
                            <div className="progress">
                              <div className="progress-bar" role="progressbar" style={{ width: `${outbound}%` }} aria-valuenow="{outbound}" aria-valuemin="0" aria-valuemax="100">{outbound}%</div>
                            </div>
                            
                            <div className="date_sec">
                              <span>Enabled on {campaigns ? `${formatDate(campaigns.created_at)}` : ''}</span>
                              <span>Use for outbound</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="graph_mainsec">
                      <div className="inner_box inboxspam_graph">
                        <div className="title_sec">
                          <h4>Inbox vs Spam</h4>
                        </div>
                        
                        <div className="graphbox">
                          <Pie data={data1} options={options1} />
                        </div>
                      </div>
                      
                      <div className="inner_box warmupemail_graph">
                        <div className="title_sec">
                          <h4>Warmup email sent</h4>
                        </div>
                        
                        <div className="graphbox">
                          <Bar data={data} options={options} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="tab-pane fade" id="general" role="tabpanel" aria-labelledby="general-tab">
                  <div className="general_contntbox">
                    <div className="smtpsettings_mainsec">
                      <div className="title_mainsec">
                        <h4>SMTP Settings (sending emails)</h4>
                      </div>
                      
                      <div className="smtpsettings_form">
                        <form>
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>Campaign Name</label>
                                    <input
                                    type="hidden"
                                    value={campaigns?.id || campaignId}
                                    onChange={(e) => setCampaigns((prev) => ({ ...prev, id: e.target.value }))}
                                  />
                                    <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Campaign Name"
                                    value={campaigns?.campaign_name || ''}
                                    onChange={(e) => setCampaigns(prev => ({ ...prev, campaign_name: e.target.value }))}
                                  />
                              </div>
                            </div>
                            
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>Subject</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Subject"
                                  value={campaigns?.subject || ''}
                                  onChange={(e) => setCampaigns(prev => ({ ...prev, subject: e.target.value }))}
                                />
                              </div>
                            </div>
                            
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>Campaign Message</label>
                                   <textarea
                                      className="form-control"
                                      value={campaigns?.campaign_message || ''}
                                      onChange={(e) => setCampaigns(prev => ({ ...prev, campaign_message: e.target.value }))}
                                    ></textarea>
                              </div>
                            </div>
                            
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>First Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    value={campaigns?.first_name || ''}
                                    onChange={(e) => setCampaigns(prev => ({ ...prev, first_name: e.target.value }))}
                                  />
                              </div>
                            </div>
                            
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>Last Name</label>
                                 <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    value={campaigns?.last_name || ''}
                                    onChange={(e) => setCampaigns(prev => ({ ...prev, last_name: e.target.value }))}
                                  />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>SMTP User Name</label>
                                 <input
                                    type="text"
                                    className="form-control"
                                    placeholder="SMTP User Name"
                                    value={campaigns?.smtp_username || ''}
                                    onChange={(e) => setCampaigns(prev => ({ ...prev, smtp_username: e.target.value }))}
                                  />
                              </div>
                            </div>
                             <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>SMTP Password</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="SMTP Password"
                                    value={campaigns?.smtp_password || ''}
                                    onChange={(e) => setCampaigns(prev => ({ ...prev, smtp_password: e.target.value }))}
                                  />
                              </div>
                            </div>
                             <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>Daily Limit</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Daily Limit"
                                  value={campaigns?.daily_limit || ''}
                                  onChange={(e) => setCampaigns(prev => ({ ...prev, daily_limit: e.target.value }))}
                                />
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>SMTP Host</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="SMTP Host"
                                  value={campaigns?.smtp_host || ''}
                                  onChange={(e) => setSMTPhost(prev => ({ ...prev, smtp_host: e.target.value }))}
                                />
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>SMTP Port</label>
                                <div className="number_radiobtn">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={campaigns?.smtp_port || ''}
                                      onChange={(e) => setCampaigns(prev => ({ ...prev, smtp_port: e.target.value }))}
                                    />
                                  
                                 {/*<div className="radiobtn_box">
                                    <label className="custom_radiobtn">
                                      <span className="label_txt">SSL</span>
                                      <input type="radio" name="radio" />
                                      <span className="checkmark"></span>
                                    </label>
                                    
                                    <label className="custom_radiobtn">
                                      <span className="label_txt">TLS</span>
                                      <input type="radio" name="radio" />
                                      <span className="checkmark"></span>
                                    </label>
                                    
                                    <label className="custom_radiobtn">
                                      <span className="label_txt">None</span>
                                      <input type="radio" name="radio" />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>*/}
                                </div>
                              </div>
                            </div>
                           <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div className="form-group form-check">
                                <label className="custom_checkbox">
                                  <span className="label_txt">Warmup Enabled</span>
                                  <input
                                    type="checkbox"
                                    checked={campaigns?.warmup_enabled === 'TRUE'}
                                    onChange={(e) =>
                                      setCampaigns(prev => ({
                                        ...prev,
                                        warmup_enabled: e.target.checked ? 'TRUE' : 'FALSE',
                                      }))
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>Warmup Limit</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={campaigns?.warmup_limit || ''}
                                  onChange={(e) => setCampaigns(prev => ({ ...prev, warmup_limit: e.target.value }))}
                                />
                              </div>
                            </div>
                            
                            {/*<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>Message Per Day (Warmups not included)</label>
                                <input type="number" className="form-control" placeholder="Message Per Day" />
                              </div>
                            </div>
                            
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>Minimum time gap (min)</label>
                                <input type="number" className="form-control" placeholder="Minimum time gap" />
                              </div>
                            </div>
                            
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div className="form-group form-check">
                                <label className="custom_checkbox">
                                  <span className="label_txt">Set a different reply to address</span>
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            */}
                          </div>
                        </form>
                      </div>
                    </div>
                    
                    
                    {/*<div className="smtpsettings_mainsec imapsettings_mainsec">
                      <div className="title_mainsec">
                        <h4>IMAP Settings (receives emails) </h4>
                      </div>
                      
                      <div className="smtpsettings_form">
                        <form>
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div className="form-group form-check">
                                <label className="custom_checkbox">
                                  <span className="label_txt">Use different email accounts for receiving emails</span>
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>IMAP Host</label>
                                <input type="text" className="form-control" placeholder="SMTP Host" />
                              </div>
                            </div>
                            
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label>IMAP Port</label>
                                
                                <div className="number_radiobtn">
                                  <div className="number_input">
                                    <input type="number" className="form-control" placeholder="0" />
                                  </div>
                                  
                                  <div className="radiobtn_box">
                                    <label className="custom_radiobtn">
                                      <span className="label_txt">SSL</span>
                                      <input type="radio" name="radio" />
                                      <span className="checkmark"></span>
                                    </label>
                                    
                                    <label className="custom_radiobtn">
                                      <span className="label_txt">TLS</span>
                                      <input type="radio" name="radio" />
                                      <span className="checkmark"></span>
                                    </label>
                                    
                                    <label className="custom_radiobtn">
                                      <span className="label_txt">None</span>
                                      <input type="radio" name="radio" />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
                              <div className="btn_boxsec">
                                <a href="#" className="btn_style">Verify Email Account</a>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>*/}
                    
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
                      <div className="btn_mainsec">
                        <button type="submit" className="btn_style" onClick={handleSave}>Update</button>
                      </div>
                    </div>
                  </div>
                </div>
                
                
                <div className="tab-pane fade" id="warmup" role="tabpanel" aria-labelledby="warmup-tab">
                  <div className="warmup_contntbox">
                    <div className="title_mainsec">
                      <h4>Warm Up</h4>
                    </div>
                    
                    <div className="emailwarmup_enabled_sec">
                      <p>Warming up an IP address involves sending low volumes of email on your dedicated IP and then systematically increasing your email volume over a period of time</p>
                      
                      <div className="custom_switch">
                        <label className="switch">
                          <span className="label_txt">Email Warmup Enabled</span>
                          <input type="checkbox" checked />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    
                    <div className="email_number_sec">
                      <div className="contnt_box">
                        <h4>Total number of warm up emails per day</h4>
                        <p>Maximum number of warm up emails that could be sent via this email account per day</p>
                      </div>
                      
                      <div className="number_inputbox">
                        <input type="number" className="form-control" placeholder="0" />
                      </div>
                    </div>
                    
                    <div className="emailwarmup_enabled_sec daily_rampup_sec">
                      <div className="custom_switch">
                        <label className="switch">
                          <span className="label_txt">Daily Rampup</span>
                          <input type="checkbox" checked />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      
                      <div className="daily_rampup_input">
                        <div className="form-group">
                          <label>Rampup increment value per day (suggested 5 per day)</label>
                          <input type="number" className="form-control" placeholder="Rampup increment value per day" />
                        </div>
                      </div>
                    </div>
                    
                    <div className="email_number_sec totalnumber_warmupemails">
                      <div className="contnt_box">
                        <h4>Randomise number of warm up emails per day</h4>
                        <p> Maximum number of emails that could be sent via this email account per day</p>
                      </div>
                      
                      <div className="range_container">
                        <div className="sliders_control">
                          <div id="fromSliderTooltip" className="slider-tooltip">115</div>
                          <input id="fromSlider" type="range" value="120" min="50" max="350" steps="10" />
                          <div id="toSliderTooltip" className="slider-tooltip">120</div>
                          <input id="toSlider" type="range" value="260" min="50" max="350" steps="10" />
                        </div>
                          
                        <div id="scale" className="scale" data-steps="50"></div>
                      </div>
                    </div>
                    
                    <div className="email_number_sec reply_rate_sec">
                      <div className="contnt_box">
                        <h4>Reply Rate (%) </h4>
                      </div>
                      
                      <div className="reply_rate_suggested">
                        <div className="form-group">
                          <label>Suggested - 20, Maximum - 100</label>
                          <input type="number" className="form-control" placeholder="Reply Rate" />
                        </div>
                      </div>
                    </div>
                    
                    <div className="email_number_sec warmup_identifier_sec">
                      <div className="contnt_box">
                        <h4>Custom Warmup Identifier Tag</h4>
                        <p>Use this two-worded tag to filter out any warmup emails from your inbox.</p>
                      </div>
                      
                      <div className="identifier_input">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="proud" />
                        </div>
                        
                        <div className="symbol"> - </div>
                        
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="range" />
                        </div>
                        
                        <div className="identifier_ans">
                          <p>= proud-range</p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="email_number_sec checkbox_mainbox">
                      <label className="custom_checkbox">
                        <div className="label_txt">
                          <h4>Enable Auto-adjust warmup/sending ratio</h4>
                          <p>Would you like us to adjust the warmups to optimize for email deliverability.</p>
                        </div>
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    
                    <div className="email_number_sec checkbox_mainbox">
                      <label className="custom_checkbox">
                        <div className="label_txt">
                          <h4>Warmup the Custom Domain Tracking Link</h4>
                          <p>We will warmup your custom domain tracking link for better deliverability.</p>
                        </div>
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    
                    <div className="email_number_sec checkbox_mainbox">
                      <label className="custom_checkbox">
                        <div className="label_txt">
                          <h4>Send warmup emails only on weekdays</h4>
                          <p>To emulate human sending patterns, Smartlead AI will automatically pause sending warmup emails on weekends.</p>
                        </div>
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                
                
                <div className="tab-pane fade" id="management" role="tabpanel" aria-labelledby="management-tab">
                  <div className="management_contntbox">
                    <div className="add_tags_mainsec">
                      <div className="title_sec">
                        <h4>Add Tags</h4>
                        <p>Create tags to make it easy for you to filter different accounts</p>
                      </div>
                      
                      <div className="inputbox">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Add Tags" />
                        </div>
                      </div>
                    </div>
                    
                    <div className="add_tags_mainsec add_client_mainsec">
                      <div className="title_sec">
                        <h4>Add Client</h4>
                        <p>Assign client to make it easy for you to filter different accounts</p>
                      </div>
                      
                      <div className="inputbox">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Add Client" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
	
      {loading && (
        <div className="loader_mainsec">
          <div className="loader_innerbox">
            <div className="spinner_box">
              <div className="spinner"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignEmails;
