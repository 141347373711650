 import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import validator from 'validator'
import { getSessionData } from './utils';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardForm from './CardForm';
const stripePromise = loadStripe('pk_test_mWl2KTmJNDdUf3DndeSdiMYV00QopYoqHy');
const apiUrl = process.env.REACT_APP_API_URL;
const homeUrl = process.env.REACT_APP_HOME_URL;

const Searchdomain = () => {
	const user = getSessionData('user');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [domaindata, setDomaindata] = useState([]);
	const [unavailabledomaindata, setUnavailableDomaindata] = useState('');
	const [tamout, setTamout] = useState(0);
	const [checkedAmounts, setCheckedAmounts] = useState([]);
	const [checkedDomains, setCheckedDomains] = useState([]);
	const [view_start_again, setViewstartagain] = useState('');
	const [step, setStep] = useState(1);
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [email, setEmail] = useState('');
	const [organization, setOrganization] = useState('');
	const [address1, setAddress1] = useState('');
	const [address2, setAddress2] = useState('');
	const [country, setCountry] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [postal_code, setPostal] = useState('');
	const [save_contact_details, setSavecontactdetails] = useState('');
	const [email_accounts, setEmailaccounts] = useState('');
	const [domains, setDomains] = useState('');
	const [single_domain, setSingledomain] = useState('');
	const [suggest_domains, Setsuggestdomains] = useState('');
	const [domain_name, setDomainname] = useState('');
	const [count, setCount] = useState(5);
	const [email_name, setEmailname] = useState('');
	const [email_username, setEmailusername] = useState('');
	const [email_usernames, setEmailUsernames] = useState([]);
	const [forward_domains, setForwarddomains] = useState('');
    const [areAllSelected, setAreAllSelected] = useState(false);

	const removeDomain = (index) => {
	  // Create a new array without the deleted domain
	  const updatedDomains = domaindata.filter((_, i) => i !== index);
	  setDomaindata(updatedDomains);

	  // Also update the amounts and checked domains
	  const updatedAmounts = checkedAmounts.filter((_, i) => i !== index);
	  const updatedCheckedDomains = checkedDomains.filter((_, i) => i !== index);
	  
	  setCheckedAmounts(updatedAmounts);
	  setCheckedDomains(updatedCheckedDomains);

	  // Update the total amount after removal
	  const newTotalAmount = updatedAmounts.reduce((sum, value) => sum + value, 0);
	  setTamout(newTotalAmount);
	};

    const handleCheckboxChange = (e) => {
    	e.preventDefault();
    	const value = parseFloat(e.target.dataset.value);
    	const domain = e.target.dataset.domain;
    	const ind = e.target.dataset.ind;
    	const newCheckedAmounts = [...checkedAmounts];
    	const newCheckedDomains = [...checkedDomains];

		newCheckedAmounts[ind] = 0; // Clear the amount
		newCheckedDomains[ind] = ''; // Clear the domain

		setCheckedAmounts(newCheckedAmounts);
		setCheckedDomains(newCheckedDomains);
	  	setTamout((prevTamout) => prevTamout - value);
	};

	const handleSelectAllChange = (e) => {
	    const checked = e.target.checked;
	    let newCheckedAmounts = [];
	    let newCheckedDomains = [];
	    let totalAmount = 0;

	    if (checked) {
	      domaindata.forEach((domain, index) => {
	        if (domain.available) {
	          const value = domain.other_info[0].price + 10;
	          newCheckedAmounts[index] = value;
	          newCheckedDomains[index] = domain.name;
	          totalAmount += value;
	        }
	      });
	    } else {
	      newCheckedAmounts = Array(domaindata.length).fill(0);
	      newCheckedDomains = Array(domaindata.length).fill('');
	    }

	    setCheckedAmounts(newCheckedAmounts);
	    setCheckedDomains(newCheckedDomains);
	    setTamout(totalAmount);
	    setAreAllSelected(checked);
	};

	useEffect(() => {		
		if(user.contact_details) {
			const contact_details = JSON.parse(user.contact_details);
	        setFname(contact_details.fname);
	        setLname(contact_details.lname);
	        setEmail(contact_details.email);
	        setOrganization(contact_details.organization);
	        setAddress1(contact_details.line1);
	        setCountry(contact_details.country);
	        setState(contact_details.state);
	        setCity(contact_details.city);
	        setPostal(contact_details.postal_code);
		}
		const allSelected =
		  checkedAmounts.length > 0 &&
		  checkedAmounts.every((amount, index) => domaindata[index]?.available && amount > 0);
		setAreAllSelected(allSelected);
	}, [checkedAmounts, domaindata]);


	if(!user) {
		return <Navigate to="/login" replace />;
	}

	if(user.domains_tool  === '0') {
		alert("you don't have to access this tool");
		return <Navigate to="/dashboard" />;
	}

	const nextStep = () => {
	    if (step !== 5) {        
	        if (step === 2 && fname === '') {
	            setError('Oops! Please enter first name');
	        } else if (step === 2 && lname === '') {
	            setError('Oops! Please enter last name');
	        } else if (step === 2 && email === '') {
	            setError('Oops! Please enter email');
	        } else if (step === 2 && organization === '') {
	            setError('Oops! Please enter organization');
	        } else if (step === 2 && address1 === '') {
	            setError('Oops! Please enter address 1');
	        } else if (step === 2 && country === '') {
	            setError('Oops! Please enter country');
	        } else if (step === 2 && city === '') {
	            setError('Oops! Please enter city');
	        } else if (step === 2 && state === '') {
	            setError('Oops! Please enter state');
	        } else if (step === 2 && postal_code === '') {
	            setError('Oops! Please enter postal code');
	        } else if (step === 3) {
			    if (email_username === '') {
			        setError('Please fill this in');
			    } else {
			        const usernamesArray = email_username.split(/[\s,]+/).map(u => u.trim()).filter(Boolean);
			        if (usernamesArray.some(username => !username.endsWith('@'))) {
			            setError('Each username must end with "@" symbol (e.g., admin@, info@, etc.)');
			        } 
			        else {
			            setError(''); // Clear error if valid
			            setStep(step + 1); // Move to next step
			        }
			    }
			} else {
	            setError(''); // Clear any previous error
	            setStep(step + 1); // Move to the next step
		    }
	    }
	};

	const prevStep = () => {
	    if (step !== 1) {
	        setStep(step - 1);
	        setError('');
	    }
	};

	const generate_domain = async (e) => {
	    e.preventDefault();
	    const endpoint = 'domains/alternative';
	    if (domain_name === '') {
	        alert('Please enter domain name');
	        return;
	    } else if (count === '') {
	        alert('Please enter count');
	        return;
	    }
	    setLoading(true);
	    try {
	        const response = await fetch(`${apiUrl}/api/${endpoint}`, {
	            method: 'POST',
	            headers: {
	                'Content-Type': 'application/json',
	            },
	            body: JSON.stringify({
	                domain_name,
	                count,
	            }),
	        });
	        setLoading(false);
	        setViewstartagain(1);
	        const data = await response.json();
	        if (data.original.available_domains) {
	            const newDomaindata = data.original.available_domains;
	            setDomaindata(newDomaindata);

	            let newCheckedAmounts = [...checkedAmounts];
	            let newCheckedDomains = [...checkedDomains];
	            let totalAmount = tamout;

	            newDomaindata.forEach((domain) => {
	                // if (domain.available) {
	                    const value = domain.other_info[0].price + 10;
	                    newCheckedAmounts.push(value);
	                    newCheckedDomains.push(domain.name);
	                    totalAmount += value;
	                // }
	            });

	            // Use setter functions to update state
	            setCheckedAmounts(newCheckedAmounts);
	            setCheckedDomains(newCheckedDomains);
	            setTamout(totalAmount);
	        } 
	        else {
	            alert(data.message);
	        }
	    } catch (error) {
	        console.error(error);
	        setLoading(false);
	    }
	};

	const generate_single_domain = async (e) => {
	    e.preventDefault();
	    const endpoint = 'domains/alternative';
	    if (single_domain === '') {
	        alert('Please enter domain name');
	        return;
	    }
	    setLoading(true);
	    try {
	        const response = await fetch(`${apiUrl}/api/${endpoint}`, {
	            method: 'POST',
	            headers: {
	                'Content-Type': 'application/json',
	            },
	            body: JSON.stringify({
	                domain_name: single_domain,
	            }),
	        });
	        setLoading(false);
	        setViewstartagain(1);
	        const data = await response.json();
	        if (data.original.available_domains) {
	            const domaindata1 = data.original.available_domains;

	            let newCheckedAmounts = [...checkedAmounts];
	            let newCheckedDomains = [...checkedDomains];
	            let totalAmount = tamout;

	            domaindata1.forEach((domain) => {
	                // if (domain.available) {
	                    const value = domain.other_info[0].price + 10;
	                    newCheckedAmounts.push(value);
	                    newCheckedDomains.push(domain.name);
	                    totalAmount += value;
	                // }
	            });

	            // Update state
	            setCheckedAmounts(newCheckedAmounts);
	            setCheckedDomains(newCheckedDomains);
	            setTamout(totalAmount);
	            setSingledomain('');
	        }
	        else {
	            // alert(data.message);
	        }
	        if(data.original.unavailable_domain) {
	        	setUnavailableDomaindata(data.original.unavailable_domain);
	        } 
	    } catch (error) {
	        console.error(error);
	        setLoading(false);
	    }
	};

	const handlePayment = async (paymentMethodId) => {
		setLoading(true);
	  	const response = await fetch(apiUrl+'/api/create-payment-intent', {
	    method: 'POST',
	    headers: {
	      'Content-Type': 'application/json'
	    },
	    body: JSON.stringify({
			amount: tamout*100,
			paymentMethodId,
			"name": fname+' '+lname,
			user_id: user.id,
			user_profile: user.name,
			fname,
	      	lname,
	      	email,
	      	domains,
	      	suggest_domains,
	      	domain_name,
	      	email_name,
	      	email_username,
	      	forward_domains,
	      	organization,
	      	save_contact_details,
	      	'phone': '+1.5555555555',
	      	'allamout': checkedAmounts,
	      	'result': checkedDomains,
	      	'address1': address1,
	      	'address2': address2,
	      	'postal_code': postal_code,
	      	'city': city,
	      	'state': state,
	      	'country': country
	    })
	  });
		
	  const paymentResult = await response.json();
	  setLoading(false);
	  if(paymentResult.success) {
	  	alert('Payment successful');
	  	window.location.reload();
	  	window.location.href = '/domain-list'
	  }
	  else {
	  	alert(paymentResult.error);
	  }  
    };
	return (
		<>
			<div className="tophead_mainsec">
		      <div className="row">
		        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
		          <div className="title_mainsec">
		            <h2>Create new domains</h2>
		          </div>
		        </div>
		      </div>
		    </div>
			<div className="step_questions_form create_domain_mainsec">
					<div className="row">
						<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div className="form_mainsec">
								<div className="create_domain_mainbox">
									<div className="stepbox_sec">
										<ul>
											<li className={[1, 2, 3, 4, 5].includes(step) ? 'active' : ''}>
												<div className="step_contnt">
													<div className="imgbox"><img src={`${homeUrl}/assets/img/domain.png`} /></div>
													<h3>Create new domains</h3>
												</div>
											</li>												
											<li className={[2, 3, 4, 5].includes(step) ? 'active' : ''}>
												<div className="step_contnt">
													<div className="imgbox"><img src={`${homeUrl}/assets/img/user-avatar.png`} /></div>
													<h3>Contact details</h3>
												</div>
											</li>	
											<li className={[3, 4, 5].includes(step) ? 'active' : ''}>
												<div className="step_contnt">
													<div className="imgbox"><img src={`${homeUrl}/assets/img/email-account.png`} /></div>
													<h3>Email accounts</h3>
												</div>
											</li>												
											<li className={[4, 5].includes(step) ? 'active' : ''}>
												<div className="step_contnt">
													<div className="imgbox"><img src={`${homeUrl}/assets/img/checkout.png`} /></div>
													<h3>Checkout</h3>
												</div>
											</li>
										</ul>
									</div>
									<div className="createnewdomains" style={{ display: step === 1 ? 'block' : 'none' }}>
										<div className="stepbox_mainsec">
											<div className="bottom_contnt">
												<div className="contnt_sec">
													<h4>Create new domains</h4>
													<p>Create as many domains as you need. You can always add more later. ( Example: yourcompanydomain.com )</p>
												</div>
												{tamout > 0 ? ( <div className="btn_mainsec">
													  	<a className="btn_style" href="#" onClick={nextStep}>Next, Total: ${tamout}</a>
													</div>
												) : <div className="btn_mainsec">
														<a className="btn_style" disabled href="#">Next, Total: $0</a>
													</div> 
												}
											</div>
										</div>
										
										<div className="domaingenerator_sec">
											<div className="contnt_sec">
												<h4>Domain auto generator</h4>
												<p>Auto generate number of domains you need.</p>
											</div>
											
											<div className="domain_inputbox">
												<div className="formsec">
													<div className="formbox">
														<div className="group-form">
															<label className="form-label">Primary domain</label>
															<input 
																type="text" 
																className="form-control" 
																placeholder="e.g. yourcompanydomain.com"
																name="domain_name"
																value={domain_name}
																onChange={(e) => setDomainname(e.target.value)}
															/>
														</div>
														
														<div className="group-form numbersdomains_input">
															<label className="form-label">Numbers of domains</label>
															<div className="inputsec">
																<input 
																	type="number" 
																	className="form-control" 
																	min="1"
																	max="50"
																	placeholder="Numbers of domains"
																	name="count"
																	value={count}
																	onChange={(e) => {
														            const value = parseInt(e.target.value, 10);
															            if (value >= 1 && value <= 50) {
															                setCount(value);
															            } else if (value > 50) {
															                setCount(50); // Ensures it never exceeds 50
															            } else {
															                setCount(""); // Clears invalid input
															            }
														            }}
																/>
																<span className="maxtxt">max 50</span>
															</div>
														</div>
													</div>
													
													<div className="btnbox">
														<div className="btn_mainsec">
															<button className="btn_style" onClick={generate_domain}>Generate domains</button>
														</div>
													</div>
												</div>
											</div>
										</div>
										
										<div className="domain_listbox">
											<div className="contnt_box">
                                               <h5>Domain list : {checkedDomains.length}</h5>
												<p>Enter the domain names you wish to use for your mailboxes and check their availability.</p>
												
												<ul>
													<li>Pro tip 1: typical setup is 5 domains and 2 mailboxes for each domain.</li>
													<li>Pro tip 2: Best deliverability and cheapest domains are .com</li>
												</ul>
												
												<p>Note: The minimum buy is 10 mailboxes.</p>
											</div>
											
											<div className="domain_list_input">
												{checkedDomains.map((domain, index) => (
												<div className="input_box" key={index}>
													<div className="group-form">
															<input 
																type="text" 
																className="form-control" 
																placeholder="Enter domain name"
																value={domain} 
																 readOnly
															/>
														</div>
														
														<div className="btnbox">
															<button 
																className="btn_style">
																Available, ${checkedAmounts[index]}/Year
																</button>
														</div>
														
														<div className="remove_box mt-2">
															<a className="btn_style" onClick={() => removeDomain(index)}>
															<i className="far fa-trash-alt"></i>
															</a>
														</div>
												</div>
												))}

												{unavailabledomaindata ? (
												<div className="input_box">
													<div className="group-form">
															<input 
																type="text" 
																className="form-control" 
																placeholder="Enter domain name"
																value={unavailabledomaindata.name} 
																 readOnly
															/>
														</div>
														
														<div className="btnbox">
															<button 
																className="btn_style unavailabledomain">
																Unavailable
																</button>
														</div>
												</div> ) : null}
												
												<div className="input_box checkavailability">
													<div className="group-form">
														<input 
															type="text" 
															className="form-control" 
															placeholder="Enter domain name" 
															value={single_domain}
															onChange={(e) => setSingledomain(e.target.value)}
														/>
													</div>
													
													<div className="btn_mainsec">
														<button 
															className="btn_style"
															onClick={generate_single_domain}
															>
															Check availability
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									
									<div className="contactdetails" style={{ display: step === 2 ? 'block' : 'none' }}>
										<div className="bottom_contnt">
											<div className="contnt_sec">
												<h4>Contact details</h4>
												<p>Enter your contact details for domain registration</p>
											</div>
											
											<div className="btn_mainsec">
												<a className="btn_style" href="#" onClick={prevStep}>Back</a>
											  	<a className="btn_style" href="#" onClick={nextStep}>Next, Total: ${tamout}</a>
											</div>
										</div>
										
										<div className="domaingenerator_sec savedcontactdetails">
											<div className="contnt_sec">
												<h4>Saved contact details</h4>
												<p>Previously saved contact details</p>
											</div>
											
											<div className="domain_inputbox">
												<div className="formsec">
													<div className="formbox">
														<div className="group-form">
															<select className="form-control" placeholder="email@yourdomain.com">
																<option value={user.email}>{user.email}</option>
															</select>
														</div>
														
														<label className="custom_checkbox">
															<span className="label_txt">Save contact details for future purchases</span>
															<input
															  type="checkbox"
															  className="form-control"
															  name="save_contact_details"
															  checked={save_contact_details} // Controlled by state
															  onChange={(e) => setSavecontactdetails(e.target.checked)} // Update state based on checked state
															/>
															<span className="checkmark"></span>
														</label>
													</div>
												</div>
											</div>
										</div>
										
										<div className="domaingenerator_sec contactfields">
											<div className="contnt_sec">
												<h4>Contact fields</h4>
												<p>All fields are required for domain registration</p>
											</div>
											
											<div className="contact_formsec">
												<div className="formsec">
													<div className="formbox">
														<div className="group-form">
															<label className="form-label">Email</label>
															<input 
																type="email" 
																className="form-control" 
																placeholder="email"
																name="email"
																value={email}
																onChange={(e) => setEmail(e.target.value)}
															/>
														</div>
														
														<div className="group-form">
															<label className="form-label">Organization name</label>
															<input 
																type="text" 
																className="form-control" 
																placeholder="Organization"
																name="organization"
																value={organization}
																onChange={(e) => setOrganization(e.target.value)}
															/>
														</div>
														
														<div className="group-form">
															<label className="form-label">First name</label>
															<input 
																type="text" 
																className="form-control" 
																placeholder="First name"  
																name="fname"
																value={fname}
																onChange={(e) => setFname(e.target.value)}
															/>
														</div>
														
														<div className="group-form">
															<label className="form-label">Last name</label>
															<input 
																type="text" 
																className="form-control" 
																placeholder="Last name"
																name="lname"
																value={lname}
																onChange={(e) => setLname(e.target.value)} 
															/>
														</div>
													</div>
													
													<div className="formbox">
														<div className="group-form">
															<label className="form-label">Address 1</label>
															<input 
																type="text" 
																className="form-control" 
																placeholder="Address 1"
																name="address1"
																value={address1}
																onChange={(e) => setAddress1(e.target.value)} 
															/>
														</div>
														
														<div className="group-form">
															<label className="form-label">Address 2</label>
															<input 
																type="text" 
																className="form-control" 
																placeholder="Address 2"
																name="address2"
																value={address2}
																onChange={(e) => setAddress2(e.target.value)} 
															/>
														</div>
														
														<div className="group-form">
															<label className="form-label">Country</label>
															<select className="form-control" placeholder="Country" value={country} onChange={(e) => setCountry(e.target.value)} >
																<option value="">Select</option>
																<option value="India">India</option>
																<option value="US">US</option>
															</select>
														</div>
														
														<div className="group-form">
															<label className="form-label">City</label>
															<input 
																type="text" 
																className="form-control" 
																placeholder="City"
																name="city"
																value={city}
																onChange={(e) => setCity(e.target.value)} 
															/>
														</div>
														
														<div className="group-form">
															<label className="form-label">Postal code</label>
															<input 
																type="text" 
																className="form-control" 
																placeholder="Postal code"
																name="postal_code"
																value={postal_code}
																onChange={(e) => setPostal(e.target.value)} 
															/>
														</div>
														
														<div className="group-form">
															<label className="form-label">State / Region</label>
															<input 
																type="text" 
																className="form-control" 
																placeholder="State"
																name="state"
																value={state}
																onChange={(e) => setState(e.target.value)} 
															/>
														</div>
													</div>
													<div className="errormainbox">
														{error ? (
															  <span className="error_msg">
															    <i className="fas fa-exclamation-triangle"></i> {error}
															  </span>
															) : null}
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="email_accounts_minsec" style={{ display: step === 3 ? 'block' : 'none' }}>
										<div className="bottom_contnt">
											<div className="contnt_sec">
												<h4>What's username of the email accounts?</h4>
												<p>This is the <a href="mailto:USERNAME@company.com">USERNAME@company.com</a></p>
											</div>

											<div className="btn_mainsec">
												<a className="btn_style" href="#" onClick={prevStep}>Back</a>
											  	<a className="btn_style" href="#" onClick={nextStep}>Next, Total: ${tamout}</a>
											</div>
										</div>
										
										<div className="important_contnt">
											<div className="contnt_sec">
												<h3>🚨 Important:</h3>
												<p>Provide us with multiple usernames if you wanted 2 or 3 emails per domain.</p>
												
												<h4>Example:</h4>
												<p>If you picked 3 email accounts per domain and the name is Jessica Davis, provide 3 examples such as:</p>
												<p>jessica@, jessica.d@ and jessicadavis@</p>
											</div>
										</div>

										<div className="emailaccount_input">
											<div className="formsec">
												<div className="formbox">					
													<label className="group-form">
														<span className="label_txt">What's username of the email accounts?</span>
														<input 
															type="text" 
															className="form-control" 
															placeholder="Type your answer here..." 
															name="email_username"
															value={email_username}
															onChange={(e) => setEmailusername(e.target.value)}
														/>
														<span className="checkmark"></span>
													</label>
												</div>
												<div className="errormainbox">
													{error ? (
														  <span className="error_msg">
														    <i className="fas fa-exclamation-triangle"></i> {error}
														  </span>
														) : null}
												</div>
											</div>
										</div>
									</div>
									
									<div className="checkoutbox" style={{ display: step === 4 ? 'block' : 'none' }}>
										<div className="bottom_contnt">
											<div className="contnt_sec">
												<h4>Checkout</h4>
												<p>Review domains and contact details before checkout.</p>
											</div>
											
											<div className="btn_mainsec">
												<a className="btn_style" href="#" onClick={prevStep}>Back</a>
												<a className="btn_style" href="#" onClick={nextStep}>Continue, Total: ${tamout}</a>
											</div>
										</div>
										
										<div className="domaingenerator_sec checkoutdetails">
											<div className="contnt_sec">
												<h4>Checkout details</h4>
												<p>Review domains and contact details before checkout.</p>
											</div>
											
											<div className="checkoutinfobox">
												<div className="infobox">
													<div className="titlebox">
														<h4>Jason cunningham</h4>
													</div>
													
													<div className="contactinfo">
														<div className="leftbox">
															<div className="info">
																<label>Email</label>
																<span>{email}</span>
															</div>
															
															<div className="info">
																<label>Organization</label>
																<span>{organization}</span>
															</div>
														</div>
														
														<div className="rightbox">
															<div className="info">
																<label>Address</label>
																<span>{address1} {address2} {city} {state} {postal_code} {country}</span>
															</div>
														</div>
													</div>
												</div>
												
												<div className="infobox pricebox">
													<div className="pricetxt">
														<h4>Total price</h4>
														<h3>${tamout} / year</h3>
														<p>Charged once. Doesn’t renew automatically</p>
													</div>
													
													<div className="domainlistbox">
														<ul>
														{checkedDomains.map((domain, index) => (									
															<li key={index}>
																<span className="domaintxt">{domain}</span>
																<span className="yeartxt">${checkedAmounts[index]} / year</span>
															</li>
														))}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="payment_mainsec" style={{ display: step === 5 ? 'block' : 'none' }}>
										<div className="bottom_contnt">
											<div className="contnt_sec">
												<h4>Checkout</h4>
												<p>Review domains and contact details before checkout.</p>
											</div>
											
											<div className="btn_mainsec">
												<a className="btn_style" href="#" onClick={prevStep}>Back</a>
											</div>
										</div>
										{tamout > 0 ? (
										    <div className="payment_form">
										      <h3>Total Amount: ${tamout}</h3>
										      <Elements stripe={stripePromise}>
										        <CardForm handlePayment={handlePayment} />
										      </Elements>
										    </div>
									  	) : null}
								  	</div>
								</div>	
							</div>
						</div>
					</div>	
				 {loading ? <div className="loader_mainsec">
	        <div className="spinner"></div>
	      </div> : <div></div>}
			</div>
		</>
	);
};

export default Searchdomain;