import React, { useState, useEffect } from "react";
import { getSessionData } from "./utils";
import { useSearchParams, Navigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;
const user = getSessionData("user");

const DomainList = () => {
  const [domains, setDomains] = useState([]);
  const [totalDomains, setTotalDomains] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("q") || "");
  const [requestInfo, setRequestInfo] = useState(null); 
  const [currentPage, setCurrentPage] = useState(1);
  const [domainsPerPage] = useState(10);

  useEffect(() => {
    if (user && user.id) {
      fetchDomains();
    } else {
      setLoading(false);
    }
  }, [query,currentPage]); // Added `query` dependency

  const fetchDomains = async () => {
    setLoading(true);
    const endpoint = `user-domains?user_id=${user.id}&q=${query}`;
    try {
      const response = await fetch(`${apiUrl}/api/${endpoint}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let data = await response.json();
      data = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setDomains(data);
      setTotalDomains(data.length);
 
    } catch (error) {
      console.error("Error fetching domains:", error);
    } finally {
      setLoading(false);
    }
  };

  // Get current domains based on pagination
  const indexOfLastDomain = currentPage * domainsPerPage;
  const indexOfFirstDomain = indexOfLastDomain - domainsPerPage;
  const currentDomains = domains.slice(indexOfFirstDomain, indexOfLastDomain);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchParams({ q: query });
    setCurrentPage(1); // Reset to first page on search
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(totalDomains / domainsPerPage);

  const getDomainAmount = (domain, parsedRequestInfo) => {
    if (parsedRequestInfo && parsedRequestInfo.result && parsedRequestInfo.allamout) {
      const normalizedDomain = domain.domain.toLowerCase().trim();

      // Find the index of the domain in request_info.result
      const domainIndex = parsedRequestInfo.result.findIndex(
        (d) => d.toLowerCase().trim() === normalizedDomain
      );

      if (domainIndex >= 0) {
        return parsedRequestInfo.allamout[domainIndex];
      }
    }
    return "N/A";
  };
  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="campaign_list test">
      <div className="tophead_mainsec">
        <div className="row">
          <div className="col-12">
            <div className="title_mainsec">
              <h2>Domain List</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="email_listing_mainsec">


        {loading ? (
          <div className="loader_mainsec">
            <div className="spinner"></div>
          </div>
        ) : currentDomains.length > 0 ? (
          <div className="row">
            <div className="col-12">
              <div className="card-body">
                <div className="table_mainsec">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Domain Name</th>
                          <th>Email</th>
                          <th>Domain ID</th>
                          <th>Order ID</th>
                          <th>Created At</th>
                          <th>Domain Status</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentDomains
                          .map((domain, index) => {
                          // Define variable outside JSX
                          let isRegistered = false;
                          let amount = 'N/A';

                          // Parse registered_info JSON if available
                          if (domain.registered_info) {
                            try {
                              const registeredData = JSON.parse(domain.registered_info);
                              isRegistered =
                                registeredData?.data?.["@attributes"]?.Registered === "true";
                            } catch (error) {
                              console.error("Error parsing registered_info JSON:", error);
                            }
                          }
                           // Parse request_info and extract amount
                            let parsedRequestInfo = null;
                            try {
                              if (domain.request_info) {
                                parsedRequestInfo = JSON.parse(domain.request_info);
                              }
                            } catch (error) {
                              console.error("Error parsing request_info JSON:", error);
                            }

                            // Get the amount from parsedRequestInfo
                            if (parsedRequestInfo) {
                              amount = getDomainAmount(domain, parsedRequestInfo);
                            }

                          return (
                            <tr key={domain.id}>
                              <td>{indexOfFirstDomain + index + 1}</td>
                              <td>{domain.domain}</td>
                              <td>{domain.email}</td>
                              <td>{domain.domainID || "N/A"}</td>
                              <td>{domain.orderID || "N/A"}</td>
                              <td>{new Date(domain.created_at).toISOString().slice(0, 16).replace("T", " ")}</td>
                              <td>
                                <span style={{ color: isRegistered ? "green" : "red", fontWeight: "bold" }}>
                                  {isRegistered ? "✓" : "✗"}
                                </span>
                              </td>
                              <td>${amount}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ) : (
          <p>No domains found.</p>
        )}
        {/* Pagination Controls */}
        <div className="pagination justify-content-end">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={index + 1 === currentPage ? "active" : ""}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default DomainList;
