import React, { useState, useEffect } from 'react';
import { getSessionData } from './utils';
import { Navigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;
const user = getSessionData('user');

const ReplyMessages = () => {
  const [loading, setLoading] = useState(false);
  const [replyMessages, setReplyMessages] = useState([]);
  const [formData, setFormData] = useState({
    subject: '',
    message: '',
  });
  const [showModal, setShowModal] = useState(false);

  // Fetch reply messages from the database
  const fetchReplyMessages = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/reply-messages?user_id=${user.id}`);
      if (!response.ok) throw new Error('Failed to fetch reply messages');
      const data = await response.json();
      setReplyMessages(data);
    } catch (error) {
      console.error('Error fetching reply messages:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.subject || !formData.message) {
      alert('Please fill in all fields.');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}/api/reply-messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.id,
          subject: formData.subject,
          message: formData.message,
        }),
      });
      if (!response.ok) throw new Error('Failed to add reply message');
      alert('Reply message added successfully');
      setFormData({ subject: '', message: '' }); // Reset form fields
      setShowModal(false); // Close modal
      fetchReplyMessages(); // Refresh the messages
    } catch (error) {
      console.error('Error adding reply message:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle message deletion
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this message?')) {
      try {
        setLoading(true);
        const response = await fetch(`${apiUrl}/api/reply-messages/${id}`, {
          method: 'DELETE',
        });
        if (!response.ok) throw new Error('Failed to delete message');
        setReplyMessages(replyMessages.filter((msg) => msg.id !== id)); // Remove deleted message from state
        alert('Message deleted successfully');
      } catch (error) {
        console.error('Error deleting message:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Load messages on component mount
  useEffect(() => {
    fetchReplyMessages();
  }, []);

  if(!user) {
    return <Navigate to="/login" />;
  }

  if(user.warm_up_tool  === '0') {
    alert("you don't have to access this tool");
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="reply_messages campaign_list test">
      <div className="tophead_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h2>Reply Messages</h2>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="btn_mainsec">
              <button className="btn_style" onClick={() => setShowModal(true)}>
                + Add New
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add Reply Messages Modal */}
      {showModal && (
        <div className="modal infopopupModal" tabIndex="-1" style={{ display: 'block' }} role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Reply Message</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Subject:</label>
                    <input
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Message:</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="form-control"
                      rows="4"
                      required
                    ></textarea>
                  </div>
                  <div className="btn_mainsec">
                    <button type="submit" className="btn_style">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Display Reply Messages in a Table */}
      <div className="email_listing_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="card-body">
              <div className="table_mainsec">
                {loading ? (
                 <div className="loader_mainsec">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  <div className="table-responsive">
                    {replyMessages.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Subject</th>
                            <th>Message</th>
                            <th class="text-end">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {replyMessages.map((msg, index) => (
                            <tr key={msg.id}>
                              <td>{index + 1}</td>
                              <td>{msg.subject}</td>
                              <td>{msg.message}</td>
                              <td>
                              <div className="action_btn justify-content-end">
                                <button className="remove_btn" onClick={() => handleDelete(msg.id)}>
                                  <i className="far fa-trash-alt"></i>
                                </button>
                              </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>No reply messages found</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyMessages;
